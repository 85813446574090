import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import classnames from 'classnames';
import { FullName } from 'common';
import Store from '../../stores/Store';

import Detail from '../Detail';
import Encounter from '../LoadableEncounter';
import Medication from '../Medication';
import MedicationOrderDetail from '../MedicationOrderDetail';
import { useTimezoneContext } from '../../../stores/PinnedTimezoneStore/context';

const MedicationOrder = ({ nested, order }) => {
  const {
    createdBy,
    description,
    details,
    encounter,
    endTime,
    medications,
    orderId,
    orderTime,
    orderedBy,
    source,
    startTime,
  } = order;
  const { timezone } = useTimezoneContext();

  let medicationEls;
  if (medications) {
    medicationEls = medications.map((med, i) => (
      <Medication
        key={i}
        medication={med}
        index={i + 1}
        size={medications.length}
      />
    ));
  }

  let detailEls;
  if (details) {
    const sortedDetails = Object.keys(details)
      .map(k => details[k])
      .sort((a, b) => {
        if (a.eventTime < b.eventTime) return -1;
        if (a.eventTime > b.eventTime) return 1;
        return 0;
      });

    detailEls = sortedDetails.map(detail => (
      <MedicationOrderDetail key={detail.eventTime} detail={detail} />
    ));
  }

  let providerEl;
  if (orderedBy) {
    const ref = Store.getUserLink({ userId: orderedBy.id });
    providerEl = (
      <Link key={orderedBy.id} to={ref}>
        <FullName person={orderedBy} />
      </Link>
    );
  }

  let userEl;
  if (createdBy) {
    const ref = Store.getUserLink({ userId: createdBy.id });
    userEl = (
      <Link key={createdBy.id} to={ref}>
        <FullName person={createdBy} />
      </Link>
    );
  }

  let potentialBadDates = false;
  if (
    startTime &&
    endTime &&
    moment(endTime)
      .tz(timezone)
      .isBefore(moment(startTime).tz(timezone))
  ) {
    potentialBadDates = true;
  }

  const dates = [
    startTime &&
      moment(startTime)
        .tz(timezone)
        .format('l h:mm A z'),
    endTime &&
      moment(endTime)
        .tz(timezone)
        .format('l h:mm A z'),
  ].filter(v => v);

  let headerLabel = dates.join('-');
  if (nested) headerLabel = `Medication Order ${headerLabel}`;
  return (
    <ul className={classnames('list-unstyled', { 'list-nested': nested })}>
      <Detail
        label={headerLabel}
        value={description}
        source={source}
        potentialBadDates={potentialBadDates}
      />
      <Detail
        label="Order Date"
        value={
          orderTime &&
          moment(orderTime)
            .tz(timezone)
            .format('l h:mm A z')
        }
      />
      <Detail label="Order ID" value={orderId} />
      <Detail label="Ordering Provider" value={providerEl} />
      <Detail label="Created User" value={userEl} />
      {encounter && <Encounter data={encounter} />}
      {medicationEls}
      {detailEls}
    </ul>
  );
};

MedicationOrder.propTypes = {
  nested: PropTypes.bool,
  order: PropTypes.shape({
    createdBy: PropTypes.shape({
      id: PropTypes.string,
    }),
    description: PropTypes.string,
    details: PropTypes.shape({}),
    encounter: PropTypes.shape({}),
    endTime: PropTypes.string,
    medications: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    orderId: PropTypes.string,
    orderTime: PropTypes.string,
    orderedBy: PropTypes.shape({
      id: PropTypes.string,
    }),
    source: PropTypes.string,
    startTime: PropTypes.string,
  }).isRequired,
};

MedicationOrder.defaultProps = {
  nested: false,
};

export default MedicationOrder;
