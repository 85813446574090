import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FullName } from 'common';
import Store from '../../stores/Store';

import Detail from '../Detail';
import MedicationHandling from '../MedicationHandling';
import WitnessUser from '../../eventTableCells/WitnessingUser';
import { useTimezoneContext } from '../../../stores/PinnedTimezoneStore/context';

const handlingEvent = (title, handling) => {
  if (handling) {
    // because of data abnormalities, the more robust handling event is sometimes the related handling
    const actualHandling =
      handling.action === undefined && handling.relatedHandling
        ? handling.relatedHandling
        : handling;
    actualHandling.eventDate = moment(actualHandling.eventTime);
    actualHandling.eventDescription = actualHandling.action;
    return (
      <div>
        <h4>{title}</h4>
        <MedicationHandling handling={actualHandling} nested />
      </div>
    );
  }

  return null;
};

const MedicationDiscrepancy = ({ discrepancy }) => {
  const {
    autoResolved,
    currentHandling,
    endTime,
    note,
    priorHandling,
    resolution,
    resolutionTime,
    resolvedBy,
    source,
    startTime,
    witnessedBy,
  } = discrepancy;

  const { timezone } = useTimezoneContext();
  const dates = [
    startTime &&
      moment(startTime)
        .tz(timezone)
        .format('l h:mm A z'),
    endTime &&
      moment(endTime)
        .tz(timezone)
        .format('l h:mm A z'),
  ];

  let resolvedEl;
  if (resolvedBy) {
    const ref = Store.getUserLink({ userId: resolvedBy.id });
    resolvedEl = (
      <Link key={resolvedBy.id} to={ref}>
        <FullName person={resolvedBy} />
      </Link>
    );
  }

  let autoRes;
  if (autoResolved) autoRes = 'Yes';
  else if (autoResolved === false) autoRes = 'No';

  return (
    <ul className="list-unstyled">
      <Detail label={dates.join('-')} value={resolution} source={source} />
      <Detail label="Note" value={note} />
      <Detail label="Resolved User" value={resolvedEl} />
      <WitnessUser witness={witnessedBy} warning />
      <Detail
        label="Resolved Date"
        value={
          resolutionTime &&
          moment(resolutionTime)
            .tz(timezone)
            .format('l h:mm A z')
        }
      />
      <Detail label="Auto Resolved" value={autoRes} />
      {handlingEvent('Prior Medication Handling', priorHandling)}
      {handlingEvent('Current Medication Handling', currentHandling)}
    </ul>
  );
};

MedicationDiscrepancy.propTypes = {
  discrepancy: PropTypes.shape({
    autoResolved: PropTypes.bool,
    currentHandling: PropTypes.shape({}),
    endTime: PropTypes.string,
    note: PropTypes.string,
    priorHandling: PropTypes.shape({}),
    resolution: PropTypes.string,
    resolutionTime: PropTypes.string,
    resolvedBy: PropTypes.shape({
      id: PropTypes.string,
    }),
    source: PropTypes.string,
    startTime: PropTypes.string,
    witnessedBy: PropTypes.shape({}),
  }).isRequired,
};

export default MedicationDiscrepancy;
