import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import PinnedTimezoneStore from '.';

const defaultContextState = {
  timezone: moment.tz.guess(),
};

export const TimezoneContext = React.createContext(defaultContextState);

export const useTimezoneContext = () => React.useContext(TimezoneContext);

export const TimezoneProvider: React.FC<React.PropsWithChildren<
  unknown
>> = observer(({ children }) => {
  const storedTimezone = PinnedTimezoneStore.storedTimezone;
  const resolvedTimezone = storedTimezone || defaultContextState.timezone;

  return (
    <TimezoneContext.Provider value={{ timezone: resolvedTimezone }}>
      {children}
    </TimezoneContext.Provider>
  );
});
