import React from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { autorun } from 'mobx';
import { Column, Cell } from 'fixed-data-table-2';
import dimensions from 'react-dimensions';

import { RouterContainer, FullName, categoryFor } from 'common';
import Registry from '../../ui/registry/Registry';
import ToggleCell from '../../ui/ToggleCell';
import ToggleAllCell from '../../ui/ToggleAllCell';
import Tooltip from '../../ui/Tooltip';
import { getResolutionDisplay } from '../../utils/resolutionDescriptions';

/**
 * Registry of cases on which a bulk action will be performed
 * @class Cases
 * @extends React.Component
 */

const Cases = observer(
  class Cases extends React.Component {
    static propTypes = {
      store: PropTypes.shape({
        completed: PropTypes.bool.isRequired,
        completedRequests: PropTypes.object.isRequired, // completedRequests is a map Object
        visibleCases: mobxPropTypes.arrayOrObservableArrayOf(
          PropTypes.shape({})
        ),
      }).isRequired,
      containerWidth: PropTypes.number,
      containerHeight: PropTypes.number,
      rowHeight: PropTypes.number,
      updateDimensions: PropTypes.func,
    };

    // NOT Observable
    disposers = [];

    constructor() {
      super();

      this.disposers.push(
        autorun(() => {
          if (!this.props) return;

          const { store } = this.props;
          if (!store) return;
        })
      );
    }

    componentWillUnmount() {
      this.disposers.forEach(d => d());
    }

    /**
     * Returns a percentage of the parent container's width.
     * @param {Number} percentage - the percentage of the parent container's width to return
     * @return {Number}             the calculated percentage
     */
    setCellWidth(percentage) {
      const { containerWidth } = this.props;
      return (percentage / 100) * containerWidth;
    }

    /**
     * Send a user to the case details view for the provided case
     * @param {Object} caseObj - the case model to view in detail
     * @return {undefined}
     */
    goTo(caseObj) {
      RouterContainer.go(`/case/${caseObj.id}`, {});
    }

    /**
     * Determine the request status for a specific case. Will check the
     * completedRequests map on the store prop to see if the case is present. If
     * present, it will look at the request status and produce a ReactDOM node
     * with the proper styling for either a successful or a failed request.
     * @param {Object} caseObj           - the case object being checked
     * @param {Map}    completedRequests - the Map containing all of the completed
     *                                     requests, to be queried
     * @return {Object|undefined}          `undefined` or  a ReactDOM node bearing
     *                                     the stylized request status
     */
    statusFor(caseObj, completedRequests) {
      const { id } = caseObj;

      let node;

      if (completedRequests.has(id)) {
        const req = completedRequests.get(id);
        const { status } = req;
        const isSuccess = status >= 200 && status < 300;

        if (isSuccess) {
          node = (
            <span className="bulk-case-actions__status">
              <i className="material-icons icon-done text-success" />
            </span>
          );
        } else {
          let iconClass = 'material-icons icon-error text-danger';
          let errorText = 'There was an error with your request.';

          if (status === 403) {
            // 403 is a forbidden error, and the only reason we'd receive that is
            // that a permission check for editing this case this way failed
            errorText = "You don't have permission to modify the case.";
          } else if (req.responseJSON && req.responseJSON.message) {
            if (req.responseJSON.message.indexOf('amazonaws') !== -1) {
              errorText = `Case action completed. However, the case owner could not be notified via email.`;
              iconClass = 'material-icons icon-warning text-warning';
            } else {
              errorText = `There was an error with your request: ${req.responseJSON.message}`;
            }
          }

          node = (
            <Tooltip
              content={req.statusText === 'error' ? errorText : req.statusText}
            >
              <span className="bulk-case-actions__status">
                <i className={iconClass} />
              </span>
            </Tooltip>
          );
        }
      }

      return node;
    }

    render() {
      /* eslint-disable no-unused-vars */
      const {
        containerWidth,
        containerHeight,
        updateDimensions,
        store,
        rowHeight,
        ...other
      } = this.props;
      const data = store.visibleCases; // don't include "stub" cases
      // eslint-disable-next-line
      const { completed, completedRequests } = store; // monitor completeness for re-rendering purposes
      // eslint-disable-next-line
      const completedRequestsLength = completedRequests.size; // ugly hack to get the table to re-render when each request is complete

      if (data.length === 0) return <div />;

      return (
        <Registry width={containerWidth} dataCount={data.length} {...other}>
          <Column
            header={<ToggleAllCell store={store} />}
            width={this.setCellWidth(5)}
            data={data}
            cell={({ rowIndex }) => (
              <ToggleCell option={data[rowIndex]} store={store} />
            )}
          />

          <Column
            header={<Cell>#</Cell>}
            width={this.setCellWidth(10)}
            cellClassName="no-wrap case-number--cell"
            data={data}
            cell={({ rowIndex }) => (
              <Cell onClick={() => this.goTo(data[rowIndex])}>
                {data[rowIndex].number ? data[rowIndex].number : null}
              </Cell>
            )}
          />

          <Column
            header={<Cell>Category</Cell>}
            width={this.setCellWidth(20)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <Cell onClick={() => this.goTo(data[rowIndex])}>
                {categoryFor(data[rowIndex])}
              </Cell>
            )}
          />

          <Column
            header={<Cell>Resolution</Cell>}
            width={this.setCellWidth(15)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <Cell onClick={() => this.goTo(data[rowIndex])}>
                {getResolutionDisplay(data[rowIndex])}
              </Cell>
            )}
          />

          <Column
            header={<Cell>Owner</Cell>}
            width={this.setCellWidth(15)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <Cell onClick={() => this.goTo(data[rowIndex])}>
                <FullName condensed person={data[rowIndex].owner} />
              </Cell>
            )}
          />

          <Column
            header={<Cell>EMR User</Cell>}
            width={this.setCellWidth(15)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <Cell onClick={() => this.goTo(data[rowIndex])}>
                <FullName condensed person={data[rowIndex].userSummary} />
              </Cell>
            )}
          />

          <Column
            header={<Cell>Patient</Cell>}
            width={this.setCellWidth(15)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <Cell onClick={() => this.goTo(data[rowIndex])}>
                {data[rowIndex]?.patientSnapshots?.length > 1 && data[rowIndex]?.type === 'privacy' ? (
                  <span>
                    {`${data[rowIndex]?.patientSnapshots.length} Patients`}{' '}
                    <i
                      className="material-icons icon-groups"
                      style={{
                        paddingBottom: '5px',
                        paddingLeft: 1,
                        color: '#643EF6',
                      }}
                    />
                  </span>
                ) : (
                  <FullName condensed person={data[rowIndex].patientSummary} />
                )}
              </Cell>
            )}
          />

          <Column
            header={<Cell />}
            width={this.setCellWidth(5)}
            data={data}
            cell={({ rowIndex }) => (
              <Cell>{this.statusFor(data[rowIndex], completedRequests)}</Cell>
            )}
          />
        </Registry>
      );
    }
  }
);

Cases.displayName = 'Cases';

export default dimensions({
  elementResize: true,
})(Cases);
