import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  reaction,
} from 'mobx';
import GeneralSettingsStore from '../GeneralSettingsStore';

const TIMEZONE_EMPTY_VALUE = '';

export const TIMEZONES = [
  { key: "User's Local Time", value: TIMEZONE_EMPTY_VALUE },
  { key: 'Mountain Daylight Time (MST7MDT)', value: 'America/Denver' },
  { key: 'Arizona: Mountain Standard Time (MST)', value: 'America/Phoenix' },
  { key: 'Alaska Daylight Time (AKST9AKDT)', value: 'America/Anchorage' },
  { key: 'Pacific Daylight Time (PST8PDT)', value: 'America/Los_Angeles' },
  { key: 'Central Daylight Time (CST6CDT)', value: 'America/Chicago' },
  { key: 'Eastern Daylight Time (EST5EDT)', value: 'America/New_York' },
  { key: 'Hawaii Standard Time (HST)', value: 'Pacific/Honolulu' },
  {
    key: 'Puerto Rico: Atlantic Standard Time (AST)',
    value: 'America/Puerto_Rico',
  },
  { key: 'Coordinated Universal Time (UTC)', value: 'UTC' },
];

class PinnedTimezoneSettings extends GeneralSettingsStore {
  category = 'services.readWrite';
  key = 'pinnedTimezone';

  constructor() {
    super();

    autorun(() => {
      this.refresh();
    }, true);

    reaction(
      () => [this.storedTimezone],
      () => {
        this.pinnedTimezoneSettingsEditValue = this.storedTimezone;
      }
    );
  }

  pinnedTimezoneSettingsEditValue = TIMEZONE_EMPTY_VALUE;

  get storedTimezone() {
    const { value } = this.response || {};
    return value || TIMEZONE_EMPTY_VALUE;
  }

  get isDirty() {
    return this.pinnedTimezoneSettingsEditValue !== this.storedTimezone;
  }

  updateTimezone = updatedTimezone => {
    this.pinnedTimezoneSettingsEditValue = updatedTimezone;
  };

  updateSettings = () => {
    if (!this.pinnedTimezoneSettingsEditValue) {
      this.delete();
      return;
    }

    this.update(this.pinnedTimezoneSettingsEditValue);
  };
}

decorate(PinnedTimezoneSettings, {
  updateTimezone: action,
  pinnedTimezoneSettingsEditValue: observable,
  isDirty: computed,
  storedTimezone: computed,
});

const PinnedTimezoneStore = new PinnedTimezoneSettings();
export default PinnedTimezoneStore;
