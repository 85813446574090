import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Detail from '../Detail';
import { useTimezoneContext } from '../../../stores/PinnedTimezoneStore/context';

const MedicationOrderDetail = ({ detail }) => {
  const { strength, volume, eventTime, frequency, rate, route } = detail;
  const { timezone } = useTimezoneContext();

  return (
    <ul className="list-unstyled list-nested">
      <Detail
        label="Order Details"
        value={
          eventTime &&
          `Updated at ${moment(eventTime)
            .tz(timezone)
            .format('l h:mm A z')}`
        }
      />
      <Detail label="Strength" value={strength} />
      <Detail label="Volume" value={volume} />
      <Detail label="Frequency" value={frequency} />
      <Detail label="Rate" value={rate} />
      <Detail label="Route" value={route} />
    </ul>
  );
};

MedicationOrderDetail.propTypes = {
  detail: PropTypes.shape({
    dose: PropTypes.shape({}),
    eventTime: PropTypes.string,
    strength: PropTypes.shape({}),
    volume: PropTypes.shape({}),
    frequency: PropTypes.string,
    rate: PropTypes.shape({}),
    route: PropTypes.string,
  }).isRequired,
};

export default MedicationOrderDetail;
