import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Detail from '../Detail';
import MedicationOrder from '../MedicationOrder';
import { useTimezoneContext } from '../../../stores/PinnedTimezoneStore/context';

const MedicationAdministration = ({ administration }) => {
  const {
    comments,
    dose,
    duration,
    eventDescription,
    endTime,
    medicationOrder,
    rate,
    recordTime,
    source,
    startTime,
  } = administration;

  const { timezone } = useTimezoneContext();
  const dates = [
    startTime &&
      moment(startTime)
        .tz(timezone)
        .format('l h:mm A z'),
    endTime &&
      moment(endTime)
        .tz(timezone)
        .format('l h:mm A z'),
  ].filter(v => v);

  return (
    <ul className="list-unstyled">
      <Detail
        label={dates.join('-')}
        value={eventDescription}
        source={source}
      />
      <Detail label="Administration Dosage" value={dose} />
      <Detail label="Administration Rate" value={rate} />
      <Detail label="Administration Duration" value={duration} />
      <Detail
        label="Documentation Time"
        value={
          recordTime &&
          moment(recordTime)
            .tz(timezone)
            .format('l h:mm A z')
        }
      />
      <Detail label="Comments" value={comments} />
      {medicationOrder && <MedicationOrder order={medicationOrder} nested />}
    </ul>
  );
};

MedicationAdministration.propTypes = {
  administration: PropTypes.shape({
    action: PropTypes.string,
    comments: PropTypes.string,
    dose: PropTypes.shape({}),
    duration: PropTypes.shape({}),
    endTime: PropTypes.string,
    eventDescription: PropTypes.string,
    rate: PropTypes.shape({}),
    recordTime: PropTypes.string,
    source: PropTypes.string,
    startTime: PropTypes.string,
    medicationOrder: PropTypes.shape({}),
  }).isRequired,
};

export default MedicationAdministration;
