import React from 'react';
import PropTypes from 'prop-types';
import { computed, observable, decorate } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import {
  AlertCategoryStore,
  Authenticated,
  LoginStore,
  PermissionStore,
  RouterContainer,
  SettingsStore,
} from 'common';
import SearchStore from '../../search/stores/SearchStore';
import EvaluationIconStore from '../../dataFeed/stores/EvaluationIconStore';
import NavBarItem from '../NavBarItem';
import Tooltip from '../../ui/Tooltip';
import { anyPermission, permissionMap } from '../../settings/helpers';
import UserMenuContainer from '../UserMenuContainer';
import DataFeedIcon from '../../dataFeed/DataFeedIcon';
import { VIEW_PATH } from '../../settings/appUsers/const';

const Item = observer(({ icon, tooltipContent, ...props }) => {
  function renderIndicator(icon) {
    if (AlertCategoryStore.creatingCases === false) {
      if (icon === 'icon-settings') {
        return (
          <i
            className={classnames('material-icons', {
              hidden: SettingsStore.creatingCases,
              'icon-pause_circle_filled': !SettingsStore.creatingCases,
              'navbar__item--settings__pause-icon': !SettingsStore.creatingCases,
            })}
            data-cy="settings-pause-icon"
          />
        );
      }
    }
  }

  return (
    <Tooltip content={tooltipContent} placement="bottom" offset={[0, 0]}>
      <NavBarItem
        {...props}
        className={classnames('navbar__item', {
          'navbar__item--settings': icon === 'icon-settings',
        })}
      >
        <i className={`material-icons ${icon}`} />
        {renderIndicator(icon)}
      </NavBarItem>
    </Tooltip>
  );
});

Item.propTypes = {
  icon: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
};
Item.displayName = 'Item';

const SearchForm = observer(
  class SearchForm extends React.Component {
    // observable
    search = '';

    // ref
    searchRef = React.createRef();

    onSubmit = e => {
      e.preventDefault();
      if (!this.searchEmpty) {
        // set to sessionStorage
        const criteria = this.searchRef.current.value;
        const key = Date.now();
        new Promise(() => SearchStore.setQueryParam(key, criteria)).then(() =>
          SearchStore.setCriteria(key)
        );

        RouterContainer.go('/search/users', {
          criteria: key,
        });
        this.search = '';
        this.searchRef.current.blur();
      }
    };

    onChange = e => {
      this.search = e.target.value;
    };

    // computed
    get searchEmpty() {
      return this.search.length === 0;
    }

    render() {
      const searchEmpty = this.searchEmpty;
      return (
        <form
          onSubmit={this.onSubmit}
          autoComplete="off"
          className={classnames({
            active: !searchEmpty,
          })}
          data-cy="qf-search"
        >
          <input
            ref={this.searchRef}
            type="text"
            value={this.search}
            placeholder="Search"
            onChange={this.onChange}
          />
          <button type="submit">
            <i className="material-icons icon-search" />
          </button>
        </form>
      );
    }
  }
);

decorate(SearchForm, {
  search: observable,
  searchEmpty: computed,
});

SearchForm.displayName = 'SearchForm';

const NavBar = observer(
  class NavBar extends React.Component {
    // computed
    get canOwnCases() {
      return (
        PermissionStore.has('CASE_MODIFY_NOTES_OWNED') &&
        !LoginStore.roles.includes('VIEW ONLY')
      );
    }

    getDefaultSettingsRoute() {
      if (PermissionStore.hasAny(permissionMap.case)) {
        return '/settings';
      } else if (PermissionStore.hasAny(permissionMap.general)) {
        return '/settings/general';
      } else if (PermissionStore.hasAny(permissionMap.template)) {
        return '/settings/templates';
      } else if (PermissionStore.hasAll(permissionMap.appUsers)) {
        return VIEW_PATH;
      } else {
        return '/settings';
      }
    }

    renderItems() {
      if (LoginStore.forceMfaSetup || LoginStore.forcePasswordChange) return;
      return (
        <div className="middle">
          <ul className="list-unstyled" data-cy="nav-list--a">
            <Authenticated permission="CASE_SEARCH">
              <Item route="/" tooltipContent="Home" icon="icon-home" />
            </Authenticated>
            <Authenticated
              permission={['CASE_SEARCH', 'CASE_SEARCH_OWNED']}
              anyPermission
            >
              <Item
                route={`/cases?resolution=null${
                  this.canOwnCases ? `&owner=${LoginStore.id}` : ''
                }`}
                tooltipContent="Cases"
                icon="icon-folder"
              />
            </Authenticated>
            <Authenticated permission="BUNDLE_VIEW">
              <Item
                route="/bundles"
                tooltipContent="Case Bundles"
                icon="icon-bundle"
              />
            </Authenticated>
            <Authenticated permission="EPCS_VIEW">
              <Item
                route="/epcs"
                tooltipContent="EPCS Report Builder"
                icon="icon-find_in_page"
              />
            </Authenticated>
            <Authenticated permission="MEDICATION_STATISTICS_VIEW">
              <Item
                route="/incidents"
                tooltipContent="Incidents"
                icon="icon-local_pharmacy"
              />
            </Authenticated>
            <Authenticated permission="ACCESS_AUTH_SEARCH_ALL">
              <Item
                route="/authorizations"
                tooltipContent="Authorizations & Permissions"
                icon="icon-verified_user"
              />
            </Authenticated>
            <Authenticated permission="VIP_REGISTRATION_SEARCH_ALL">
              <Item
                route="/vips?status=ACTIVE&createdBy=UserOnly"
                tooltipContent="VIP Registrations"
                icon="icon-star"
              />
            </Authenticated>
            <Authenticated permission="PERSON_TAG_VIEW">
              <Item
                route="/user-tags"
                tooltipContent="User Tags"
                icon="icon-label"
              />
            </Authenticated>
            <Authenticated permission="CASE_REPORT_VIEW">
              <Item
                route="/reports/cases"
                tooltipContent="Performance Reporting"
                icon="icon-speed"
              />
            </Authenticated>
            <Authenticated
              permission={['DATA_FEED_VIEW', 'BETA']}
              anyPermission
            >
              <NavBarItem className="navbar__item" route="/data-feed">
                <DataFeedIcon errorCount={EvaluationIconStore.errorCount} />
              </NavBarItem>
            </Authenticated>
            <Authenticated permission={anyPermission} anyPermission>
              <Item
                route={this.getDefaultSettingsRoute()}
                tooltipContent="Settings"
                icon="icon-settings"
              />
            </Authenticated>
          </ul>
          <ul className="list-unstyled list-right" data-cy="nav-list--b">
            <Item
              href="https://help.protenus.com/hc/en-us"
              external
              tooltipContent="Help Center"
              icon="icon-info"
            />
            <Item
              href="http://pandas.protenus.com"
              external
              tooltipContent="PANDAS Habitat"
              icon="icon-people"
            />
            <Item
              href="https://academy.protenus.com/"
              external
              tooltipContent="Protenus Academy"
              icon="icon-school"
            />
          </ul>
        </div>
      );
    }

    render() {
      return (
        <nav className="navbar">
          {this.renderItems()}
          <div className="right">
            <Authenticated permission="USER_VIEW">
              <div className="global_search">
                <SearchForm />
              </div>
            </Authenticated>
            <UserMenuContainer />
          </div>
        </nav>
      );
    }
  }
);

decorate(NavBar, {
  canOwnCases: computed,
});

NavBar.displayName = 'NavBar';

export default NavBar;
