import React from 'react';
import PropTypes from 'prop-types';
import { HalUtils, DateHelpers } from 'common';
import styles from './index.module.scss';
import CustodyChangeRow from './CustodyChangeRow';
import ClockedOutRow from './ClockedOutRow';
import EventRow from './EventRow';
import { person } from './PropTypes';

const EventList = ({ user, userLink, events }) => {
  const timezone = DateHelpers.getCurrentAppUserTimezone();
  return (
    <div className={styles.tablewrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th className={styles.orderHeader}>Order ID</th>
            <th>Action</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {events.map(
            ({
              user: eUser,
              patient,
              date,
              start,
              end,
              startReason,
              endReason,
              order,
              action,
              detail,
              id,
              reason,
              changeInCustody,
              clockedOutTransaction,
            }) => {
              /* grab user from event since change in custody events have diff users, discrepancies don't have users on the event so use the user from the incident */
              const userId = (eUser && eUser.id) || user.id;
              const patientId = patient && patient.id;
              const link = HalUtils.buildActivityViewLink({
                userId,
                patientId,
                minEventDate: date,
                maxEventDate: date,
                focusEvent: id,
                // If no patient (discrepancies) link to userOnlyEvents row
                type: patientId ? 'event' : 'userOnlyEvents',
              });
              if (changeInCustody) {
                return (
                  <React.Fragment key={`${id}+${date}-custody-change`}>
                    <CustodyChangeRow
                      user={changeInCustody.fromUser}
                      custodyChangeUser={changeInCustody.toUser}
                      link={link}
                    />
                    <EventRow
                      date={date}
                      order={order}
                      action={action}
                      reason={reason}
                      detail={detail}
                      link={link}
                      timezone={timezone}
                    />
                  </React.Fragment>
                );
              }
              if (clockedOutTransaction) {
                return (
                  <React.Fragment key={`${id}+${date}-clocked-out`}>
                    <ClockedOutRow
                      user={user}
                      userLink={userLink}
                      date={date}
                      start={start}
                      startReason={startReason}
                      end={end}
                      endReason={endReason}
                      timezone={timezone}
                    />
                  </React.Fragment>
                );
              }
              return (
                <EventRow
                  key={`${id}+${date}`}
                  date={date}
                  order={order}
                  action={action}
                  reason={reason}
                  detail={detail}
                  link={link}
                  timezone={timezone}
                />
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

EventList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      order: PropTypes.string,
      action: PropTypes.string,
      detail: PropTypes.string,
      id: PropTypes.String,
      changeInCustody: PropTypes.shape({
        fromUser: PropTypes.object,
        toUser: PropTypes.object,
      }),
    })
  ).isRequired,
  user: person,
  userLink: PropTypes.string,
};

EventList.defaultProps = {
  user: {},
};

export default EventList;
