import React from 'react';
import * as Sentry from '@sentry/react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { autorun, reaction, toJS } from 'mobx';

import { LoginStore, RouterContainer, AppUserStore } from 'common';

import Viewport from './Viewport';
import NotificationStore from './stores/NotificationStore';
import { rebuildTooltipsOnMutation } from './ui/Tooltip';

import setupAnalytics from '../setupAnalytics';
import ErrorBoundaryPandy from './ui/ErrorBoundaryPandy';

// Time between checks to determine if the user is still active
const loginStatusInterval = 15000;
let loginStatusTimeout = setTimeout(checkLoginStatus, loginStatusInterval);

/**
 * Inspects the JWT to determine if the user has been idle for too long,
 * redirecting the user to the login screen if needed.
 */
function checkLoginStatus() {
  if (
    LoginStore.user &&
    LoginStore.user.jwtExpiration < new Date().getTime() + loginStatusInterval
  ) {
    const jwt = localStorage.getItem('jwt');
    // if there are multiple tabs open, it's possible that one of the tabs has a new and updated jwt token
    // so we should get that token instead of what's in the loginStore
    if (jwt === LoginStore.jwt) {
      // session will be expired by next check - kick them out
      NotificationStore.dismissAll();
      LoginStore.logout(true);
    } else {
      LoginStore.jwt = jwt;
    }
  } else if (
    LoginStore.user &&
    LoginStore.user.jwtExpiration < new Date().getTime() + 1000 * 60 * 3
  ) {
    // session is nearly expired - provide a warning
    const onClick = () => {
      LoginStore.renewCheck();
      NotificationStore.dismiss('sessionWarn');
    };
    const message =
      LoginStore.lastRenewStatus === 'FAILED'
        ? 'Your session will end shortly and the network connection has been disrupted, retry?'
        : "Still there? We've noticed you haven't been active and your session will end shortly.";
    const action =
      LoginStore.lastRenewStatus === 'FAILED'
        ? 'Retry session renewal'
        : 'Continue session';
    const content = (
      <span>
        <i className="material-icons icon-warning" />
        {`${message} `}
        <span className="prot-a" role="button" tabIndex="0" onClick={onClick}>
          {action}
        </span>
      </span>
    );
    NotificationStore.add({
      level: 'warning',
      autoClose: false,
      toastId: 'sessionWarn',
      onClose: () => {
        LoginStore.renewCheck();
      },
      content,
    });
  }

  if (loginStatusTimeout) clearTimeout(loginStatusTimeout);

  loginStatusTimeout = setTimeout(checkLoginStatus, loginStatusInterval);
}

autorun(() => {
  if (LoginStore.forcePasswordChange) {
    // password must be changed (either expired or being reset). Redirecto to dedicated reset password view
    RouterContainer.go('/resetPassword');
  } else if (LoginStore.forceMfaSetup) {
    // mfa is required, but not enabled. Redirect to the dedicated mfa view
    RouterContainer.go('/mfa');
  }

  if (LoginStore.loggedIn) {
    LoginStore.accountId()
      .then(caseSafeAccountId => {
        setupAnalytics(
          { ...LoginStore.user, caseSafeAccountId },
          toJS(AppUserStore.allUsers)
        );
      })
      .catch(() => {
        setupAnalytics(LoginStore.user, toJS(AppUserStore.allUsers));
      });
  }
});

reaction(
  () => [LoginStore.user],
  () => {
    NotificationStore.dismiss('sessionWarn');
  }
);

reaction(
  () => [LoginStore.lastRenewStatus],
  () => {
    if (LoginStore.lastRenewStatus === 'FAILED') {
      const content = (
        <span>
          <i className="material-icons icon-warning" />
          Heads up! We&apos;ve detected some network connectivity issues. You
          are free to continue working in the application but it is recommended
          you save any work in another location until connectivity is restored.
        </span>
      );
      NotificationStore.add({
        level: 'warning',
        toastId: 'renewWarn',
        autoClose: 15000,
        content,
      });
    } else if (LoginStore.lastRenewStatus === 'RESTORED') {
      const content = (
        <span>
          <i className="material-icons icon-check_circle" />
          Network connectivity has been restored.
        </span>
      );
      NotificationStore.dismiss('renewWarn');
      NotificationStore.add({
        level: 'success',
        toastId: 'renewWarn',
        content,
      });
    }
  }
);

rebuildTooltipsOnMutation();

// do not make this reactive!!!
const Main = () => (
  <Sentry.ErrorBoundary>
    <ErrorBoundaryPandy>
      <Router>
        <Route path="/" component={Viewport} />
      </Router>
    </ErrorBoundaryPandy>
  </Sentry.ErrorBoundary>
);

export default Main;
